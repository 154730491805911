import React from "react";
import { Link } from "react-router-dom";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Header = (props) => {
  const isActive = (path, match, location) => {
    if (!match) {
      return false;
    }
    return true;
  };

  return (
    <>
      <input type="checkbox" id="toggle-menu" />
      <label className="toggle-menu-header" htmlFor="toggle-menu">
        <em className="l1" />
        <em className="l2" />
        <em className="l3" />
      </label>
      <label className="menu-hider" htmlFor="toggle-menu" />
      <div className="logo-header-block">
        <Link to="/" className="header-logo">
          <img src="/crowne_logo.svg" />
        </Link>
      </div>

      <p className="language">
        <div className="d-flex justify-content-end">
          {props.currentLanguageCode === "tr" ? (
            <>
              <button
                className="btn datepicker_btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.dispatch(changeLanguage("en"));
                }}
              >
                EN
              </button>
            </>
          ) : (
            <button
              className="btn datepicker_btn"
              onClick={(e) => {
                e.preventDefault();
                props.dispatch(changeLanguage("tr"));
              }}
            >
              TR
            </button>
          )}
        </div>
      </p>
      <div id="menu-sidebar" className="menu menu-sidebar">
        <div className="menu-scroll">
          <div className="menu-clear" />
          <div className=" d-flex justify-content-center mt-5"> </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/aura">
              {props.currentLanguageCode === "tr" ? (
                <span>Aura Restaurant</span>
              ) : (
                <span>Aura Restaurant</span>
              )}
            </NavLink>
          </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/breakfast">
              {props.currentLanguageCode === "tr" ? (
                <span>Kahvaltı</span>
              ) : (
                <span>Breakfast</span>
              )}
            </NavLink>
          </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/lobby">
              {props.currentLanguageCode === "tr" ? (
                <span>One Bar & Lounge</span>
              ) : (
                <span>One Bar & Lounge</span>
              )}
            </NavLink>
          </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/room">
              {props.currentLanguageCode === "tr" ? (
                <span>Oda Servisi</span>
              ) : (
                <span>Room Service</span>
              )}
            </NavLink>
          </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/minibar">
              {props.currentLanguageCode === "tr" ? (
                <span>Minibar</span>
              ) : (
                <span>Minibar</span>
              )}
            </NavLink>
          </div>
          <div className="submenu-item">
            <input type="checkbox" className="toggle-submenu" />
            <NavLink className="menu-item" to="/massage">
              {props.currentLanguageCode === "tr" ? (
                <span>Spa </span>
              ) : (
                <span>Spa </span>
              )}
            </NavLink>
          </div>
          <NavLink className="feed_btn_2" to="/feedback">
            {props.currentLanguageCode === "tr" ? (
              <span>Geri bildirimde bulunun</span>
            ) : (
              <span>Give Feedback</span>
            )}
          </NavLink>

          <div className="sub_info">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <br />
                <h6>Macun, 171. Sk. 2/1, 06374</h6>
                <h6>Yenimahalle/Ankara</h6>

                <h6>
                  {" "}
                  <a href="tel:+03123200000"> +90 312 320 00 00</a>
                </h6>
                <h6>
                  <a href="mailto: reservation@crowneplazaankara.com.tr">
                    reservation@crowneplazaankara.com.tr
                  </a>
                </h6>

                <h6>
                  <a className="" href="mailto: info@crowneplazaankara.com.tr">
                    info@crowneplazaankara.com.tr
                  </a>
                </h6>
                <div className="d-flex justify-content-center">
                  {" "}
                  <a
                    href="https://www.instagram.com/crowneplazaankara/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.facebook.com/crowneplazaankarahotel/"
                    target="_blank"
                  >
                    {" "}
                    <FaFacebookSquare />
                  </a>
                  <a
                    href="https://tr.linkedin.com/company/crowne-plaza-ankara"
                    target="_blank"
                  >
                    {" "}
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dsd: PropTypes.object,
};

export default connect()(multilanguage(Header));
